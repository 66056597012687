<template>
  <v-row class="rowcontent">
    <v-col md="8" cols="10">
      <div class="text-center paginations">
        <div class="content">
          <v-row>
            <v-col @click="pageBack" class="buttonPaginationBack" md="2" sm="2">Anterior</v-col>
            <v-col class="paginationCol" md="8" sm="10">
              <v-pagination v-model="page" :length="pages"></v-pagination>
            </v-col>
            <v-col @click="pageNext" class="buttonPaginationNext" md="2" sm="2">Próximo</v-col>
          </v-row>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    functionEmit: {
      type: String,
      default: '',
    },
    pages: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      page: 1,
      totalPages: this.pages,
    };
  },
  watch: {
    page() {
      this.changePage();
    },
  },
  methods: {
    changePage() {
      this.$bus.$emit(`${this.functionEmit}`, this.page);
    },
    pageBack() {
      if (this.page > 1) {
        this.page = this.page - 1;
        this.changePage();
      }
    },
    pageNext() {
      if (this.page < this.pages) {
        this.page = this.page + 1;
        this.changePage();
      }
    },
  },
  created() {
    this.$bus.$on('reloadPagination', (payload) => {
      this.page = payload;
    });
  },
};
</script>
<style lang="scss">
.paginations {
  border: 1px solid #c4c4c4;
  border-radius: 15px;
  height: 45px;
  margin-top: -2%;
  padding: 02px 10px 02px 10px;
  background-color: none;
}
.buttonPaginationNext {
  margin-top: -0.6%;
  cursor:pointer;
  color:#a09e9e;
  font-size:14px
}
.buttonPaginationBack {
  margin-top: -0.6%;
  cursor:pointer;
  color:#a09e9e;
  font-size:14px

}
.content {
  margin-left: 2%;
  margin-right: 2%;
}
.paginationButtonActive {
  font-size: 12px;
  text-align: center;
  color: #a09e9e;
  padding: 2px 13px 2px 12px;
  font-weight:bold;
}
.contentPagination {
  text-align:center;
}
.paginationCol {
    margin-top: 0;
    padding: 0;
}

@media only screen and (max-device-width: 767px) {
  .pagination {
    margin: 0;
    width: 130%;
  }
  .buttonPaginationNext {
    font-size: 10px;
    z-index: 1;
  }
  .buttonPaginationBack {
    font-size: 10px;
    z-index: 1;
  }
  .paginationCol {
    width: 60%;
  }
  .paginations {
    margin-right: 5%;
    margin-left: -1%;
  }
}
</style>
