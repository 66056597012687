var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.$vuetify.breakpoint.smAndDown)?_c('FiltersMobile',{attrs:{"status":_vm.statusFilter},on:{"emitFunction":_vm.getFilterTableItems}}):_vm._e(),_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTable),expression:"showTable"}],staticClass:"colSolicitation",attrs:{"md":"4","cols":"12"}},[_c('span',{staticClass:"titleSolicitation"},[_vm._v("Histórico de solicitações")])]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTable),expression:"showTable"}],attrs:{"cols":"5"}}),_c('v-col',{staticClass:"colImportacaoMassiva",attrs:{"cols":"3"}},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTable),expression:"showTable"}],staticClass:"btnImport",on:{"click":_vm.handleClick}},[_vm._v("Exportar")])])],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTable),expression:"showTable"}],staticClass:"pl-0 filter-desktop",attrs:{"cols":"12"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('FiltersDesktop',{attrs:{"status":_vm.statusFilter},on:{"emitFunction":_vm.getFilterTableItems}}):_vm._e()],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTable),expression:"showTable"}]},[_c('v-col',{staticClass:"tableImport",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"dataTableContent",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.dataTableItems,"items-per-page":10,"mobile-breakpoint":600,"custom-sort":_vm.service.customSort},scopedSlots:_vm._u([{key:"item.supplier",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.supplier))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.supplierName))])])]}},{key:"item.externalId",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.externalId))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.externalIdFull))])])]}},_vm._l((_vm.headers),function(h,index){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [(_vm.headerToolTips(header.value))?_c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text.split(' ')[0]))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(h.text))])]):(!_vm.headerToolTips(header.value))?_c('span',{key:index},[_vm._v(_vm._s(header.text))]):_vm._e()]}}}),{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.statusColor[item.status]},[_vm._v(" "+_vm._s(item.statusText)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('SeeSolicitation',{attrs:{"dataSupplier":item},on:{"emitFunction":_vm.getFilterTableItems}})]}}],null,true)})],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTable),expression:"showTable"}],staticClass:"pagination"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-spacer'):_vm._e(),_c('v-col',{staticClass:"colPagination",attrs:{"md":"8","cols":"12"}},[_c('Pagination',{attrs:{"pages":_vm.totalPages,"functionEmit":'paginationFilter'}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }