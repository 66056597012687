<template>
  <v-container class="row-filters">
    <v-col class="filter" cols="2">
      <p class="filter-text">Filtrar por</p>
    </v-col>

    <v-col cols="3" sm="6" md="3" class="date-filter">
      <v-btn class="ma-2 date-button" x-large outlined color="grey">
        <DateRanger :emitFunction="'pushRangeDate'" /></v-btn>
    </v-col>
    <v-col class="d-flex" cols="3" md="3">
      <v-select
          class="select-filter"
          item-color="#D70472"
          v-model="selectStatus"
          :items="status"
          label="Status"
          outlined
          multiple
        >
      <template class="items-chips" v-slot:selection="{ item, index }">
          <span  v-if="index === 0 && selectStatus.length === 1"  class="list-items">
            {{ item.text }}
          </span>
          <span  v-if="index === 0 && selectStatus.length > 1"  class="list-items">
            {{ item.text.substr(0, 10) + '...' }}
          </span>
        <span
          v-if="index === 1"
          class="grey--text caption countItemSelect"
        >(+{{ selectStatus.length - 1 }})</span>
      </template>
      </v-select>
    </v-col>
    <v-col cols="12" sm="6" md="3">
      <v-text-field
        class="input-filter"
        v-mask="'##.###.###/####-##'"
        v-model="document"
        label="CNPJ"
        solo
        flat
        outlined
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="6" md="3">
      <v-text-field
        class="input-filter"
        type="number"
        v-model="externalId"
        label="Pedido"
        solo
        flat
        outlined
      ></v-text-field>
    </v-col>

    <v-btn
      @click="firstFilter"
      :loading="btnLoading"
      class="buttonFilter"
      small
      outlined
    >Filtrar</v-btn>
  </v-container>

</template>

<script>
import { mask } from 'vue-the-mask';
import DateRanger from '../Date/DateRanger.vue';
import service from '../../service';

export default {

  directives: {
    mask,
  },
  components: {
    DateRanger,
  },
  name: 'DesktopFilter',
  props: ['status', 'emitFunction', 'checklock', 'hideStatus'],
  data() {
    return {
      document: '',
      startDate: '',
      endDate: '',
      supplier: '',
      dialog: false,
      socialReason: '',
      page: 1,
      btnLoading: false,
      externalId: '',
      selectStatus: [],
      service,
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
  },
  methods: {
    firstFilter() {
      this.page = 1;
      this.filter();
    },
    filter() {
      const { page } = this;
      const args = { };
      if (page) args.page = page;
      if (this.document) args.document = service.cleanDocument(this.document);
      if (this.externalId) args.externalId = this.externalId;
      if (this.selectStatus.length >= 1) args.status = this.selectStatus;
      else if (this.selectStatus.length === 0) delete args.status;
      else if (this.selectStatus !== null) args.status = this.selectStatus;
      if (this.startDate) args.startDate = this.startDate;
      if (this.endDate) args.endDate = this.endDate;
      args.perPage = 10;
      this.btnLoading = true;
      this.$emit('emitFunction', args);
    },
  },
  created() {
    this.$bus.$on('pushRangeDate', (payload) => {
      if (payload) {
        const data = payload.split(' ~ ');
        this.startDate = this.$moment(data[0], 'DD/MM/YYYY').format();
        this.endDate = this.$moment(data[1], 'DD/MM/YYYY').format();
      } else {
        this.startDate = null;
        this.endDate = null;
      }
    });
    this.$bus.$on('paginationFilter', (payload) => {
      this.page = payload;
      this.filter();
    });
    this.$bus.$on('loadingButtonFilters', (payload) => {
      this.btnLoading = payload;
    });
  },
};
</script>

<style lang="scss" scoped>
.filter {
  margin-top:-01%
}
.list-items {
  font-size:13px;
  margin-top:6%;
}
.row-filters {
  white-space: nowrap;
  display: inline-flex;
  width: 800px !important;
  padding-left: 0;
}
.input-filter::v-deep #input-55 {
  color: #7d7d7d;
}
.input-filter::v-deep #input-58 {
  color: #7d7d7d;
}
.select-filter::v-deep .v-label.v-label--active.theme--light.primary--text{
  margin-top: -5%;
  // margin-left: 3%;
}
.select-filter::v-deep .v-label.v-label--active.theme--light{
  margin-top: -5%;
}

.date-filter {
  margin-top: -8px;
  margin-right: -10px;
  margin-left: -40px;
}

.date-filter::v-deep .v-btn:not(.v-btn--round).v-size--x-large {
  min-width: 155px;
}

.date-button {
  height: 40px !important;
  width: 90%;
}

.DateRanger {
  cursor: pointer;
}
.firstField {
  margin-left:-3%
}

.filter-text {
  margin-top:15px;
  font-size: 15px;
  font-weight: bold;
  color: #8c8c8c;
}

.buttonFilter {
  border: #d70472 1px solid;
  color: #d70472;
  width: 100%;
  border-width: thin;
  font-size: 0.9rem;
  width: 10%;
  height:40px !important;
  margin-top: 1.3%;
  max-width: 75px;
}
.select-filter::v-deep .v-chip.v-size--default {
  font-size: 12px;
  margin-top: 12px;
}
.countItemSelect {
  margin: 6% 0% 0% 3%;
}

</style>
