<template>
    <v-container>
      <FiltersMobile @emitFunction="getFilterTableItems"
      :status="statusFilter" v-if="$vuetify.breakpoint.smAndDown" />
      <v-row>
        <v-col class="colSolicitation" md="4" cols="12" v-show="showTable">
          <span class="titleSolicitation">Histórico de solicitações</span>
        </v-col>
        <v-col cols="5" v-show="showTable"></v-col>
        <v-col class="colImportacaoMassiva" cols="3">
          <button @click="handleClick" class="btnImport" v-show="showTable">Exportar</button>
        </v-col>
      </v-row>
      <v-col cols="12" v-show="showTable" class="pl-0 filter-desktop">
        <FiltersDesktop @emitFunction="getFilterTableItems"
        :status="statusFilter" v-if="$vuetify.breakpoint.mdAndUp" />
      </v-col>
      <v-row v-show="showTable">
        <v-col class="tableImport" cols="12">
          <v-data-table
            hide-default-footer
            :headers="headers"
            :items="dataTableItems"
            :items-per-page="10"
            :mobile-breakpoint="600"
            class="dataTableContent"
            :custom-sort="service.customSort"

          >

          <template v-slot:item.supplier="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{item.supplier}}</span>
              </template>
              <span>{{item.supplierName}}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.externalId="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{item.externalId}}</span>
              </template>
              <span>{{item.externalIdFull}}</span>
            </v-tooltip>
          </template>
          <template
            v-for="(h, index) in headers"
            v-slot:[`header.${h.value}`]="{ header }"
          >
            <v-tooltip v-if="headerToolTips(header.value)" bottom :key="index">
              <template v-slot:activator="{ on }">
                <span v-on="on">{{header.text.split(' ')[0]}}</span>
              </template>
              <span>{{h.text}}</span>
            </v-tooltip>
            <span v-else-if="!headerToolTips(header.value)" :key="index" >{{header.text}}</span>
          </template>
          <template v-slot:item.status="{ item }">
            <span :class="statusColor[item.status]">
              {{ item.statusText }}
            </span>
          </template>
            <template v-slot:item.actions="{ item }">
              <SeeSolicitation @emitFunction="getFilterTableItems"
              :dataSupplier="item"></SeeSolicitation>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row class="pagination" v-show="showTable">
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-col class="colPagination" md="8" cols="12">
            <Pagination :pages="totalPages" :functionEmit="'paginationFilter'" />
          </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Pagination from '../../../components/Pagination.vue';
import FiltersDesktop from '../../../components/Filter/DesktopFilter.vue';
import FiltersMobile from '../../../components/Filter/MobileFilter.vue';
import service from '../../../service';
import SeeSolicitation from './Solicitation.vue';

export default {
  name: 'myPayments',

  components: {
    FiltersDesktop,
    FiltersMobile,
    Pagination,
    SeeSolicitation,
  },
  data: () => ({
    showTable: true,
    headers: [
      {
        text: 'ID da Operação',
        align: 'center',
        filterable: false,
        value: 'id',
      },
      {
        text: 'Data da Solicitação',
        align: 'center',
        filterable: false,
        value: 'createDate',
      },
      {
        text: 'Pedido',
        align: 'center',
        filterable: false,
        value: 'externalId',
      },
      {
        text: 'CNPJ',
        align: 'center',
        filterable: false,
        value: 'document',
      },
      {
        text: 'Fornecedor',
        align: 'center',
        filterable: false,
        value: 'supplier',
      },
      {
        text: 'Valor',
        align: 'center',
        filterable: false,
        value: 'value',
      },
      {
        text: 'Status',
        align: 'center',
        filterable: false,
        value: 'status',
      },
      {
        text: 'Ações',
        align: 'center',
        sortable: false,
        value: 'actions',
      },
    ],
    service,
    statusFilter: [
      { text: 'Pendente', value: 0 },
      { text: 'Aprovado', value: 1 },
      { text: 'Reprovado', value: 3 },
      { text: 'Cancelado', value: 2 },
    ],
    statusColor: {
      0: 'grey--text',
      1: 'teal--text',
      2: 'grey--text',
      3: 'red--text',
      null: 'grey--text',
    },

  }),
  computed: {
    dataTableItems() {
      return this.getRequests.map(el => ({
        id: el.operationId.toString(),
        _id: el._id.toString(),
        createDate: this.$moment(el.createDate).format('DD/MM/YYYY'),
        externalIdFull: el.externalId ? el.externalId : '',
        externalId: el.externalId ? `${el.externalId.substr(0, 18)}` : '',
        document: service.maskToDocument(el.supplier.document),
        supplier: `${el.supplier.name.substr(0, 18)}`,
        supplierName: el.supplier.name,
        value: this.formatValue(el.value, el.typeSymbol),
        status: el.status.id,
        statusText: el.status.statusText,
        files: el.attachment,
      }));
    },
    totalPages() {
      return parseInt(this.getTotalPages, 10);
    },
    ...mapGetters({
      getRequests: 'Request/getRequests',
      getTotalPages: 'Request/getTotalPages',
      getExportRequest: 'Request/getExportRequest',
      getFileNameRequest: 'Request/getFileNameRequest',
    }),
  },
  methods: {
    ...mapActions({
      setBalance: 'Request/setBalance',
      setRequests: 'Request/setRequests',
      setExportRequest: 'Request/setExportRequest',
    }),
    formatValue(value, symbol) {
      const val = symbol === '-' ? -value : value;
      return this.service.formatCurrency(val);
    },
    getFilterTableItems(args) {
      this.setRequests(args);
      this.$bus.$emit('loadingButtonFilters', false);
    },
    headerToolTips(value) {
      if (value === 'createDate' || value === 'id') return true;
      return false;
    },
    forceFileDownload() {
      const url = window.URL.createObjectURL(new Blob(['\ufeff', this.getExportRequest]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${this.getFileNameRequest}`);
      document.body.appendChild(link);
      link.click();
    },
    async handleClick() {
      await this.setExportRequest();
      this.forceFileDownload();
    },
  },
  created() {
    this.$bus.$on('tableVisible', (payload) => {
      this.showTable = payload;
    });
    this.setRequests({ perPage: 10 });
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/seller/myPayments.scss';
</style>
