<template>
  <v-row class="rowDialog">
    <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.smAndDown" max-width="430">
      <template v-slot:activator="{ on }">
        <button v-if="dataSupplier.status === 0" class="action" @click="openModal" v-on="on">
          APROVAR
        </button>
        <button v-else class="action" @click="openModal" v-on="on">VER</button>
      </template>
      <v-card class="contentCard">
        <v-card-title class="headline">
          <v-col cols="10" class="title-payments">Solicitação de Pagamento</v-col>
          <v-spacer></v-spacer>
          <v-col class="icon" cols="1">
            <v-icon @click="closeModal" class="close-icon">mdi-window-close</v-icon>
          </v-col>
        </v-card-title>

          <v-divider class="mx-6 ml-6 divider divider-consult"></v-divider>

        <v-col>
          <v-alert class="alert" dense outlined
          v-if="responseError">{{responseError}}</v-alert>
        </v-col>
        <v-card-text>
          <v-container class="contentForm" v-if="showModal">
            <v-form class="form" v-if="!requestAprove && !requestReprove" ref="form"  @submit.prevent="submit">
              <v-row>
                <v-col cols="12" class="iconSearchFilters">
                  <v-text-field
                    readonly
                    v-model="dataSupplier.document"
                    label="CNPJ"
                    v-mask="'##.###.###/####-##'"
                    :rules="validationCNPJ"
                    placeholder="Digite o CNPJ"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="iconSearchFilters">
                  <v-text-field
                    readonly
                    v-model="dataSupplier.supplierName"
                    label="Razão social"
                    :rules="validationSocial"
                    placeholder="Preenchimento automatico"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="iconSearchFilters">
                  <v-text-field
                    readonly
                    v-model="dataSupplier.externalIdFull"
                    label="Pedido"
                    :rules="validationRequest"
                    placeholder="Digite o número do pedido"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12"  class="iconSearchFilters">
                  <v-text-field
                    readonly
                    v-model="dataSupplier.value"
                    label="Valor solicitado"
                    v-money="money"
                    :rules="validationValue"
                    validate-on-blur
                    required
                  ></v-text-field>
                </v-col>
                <v-row v-if="dataSupplier.files.length > 0">
                  <v-col md="11" cols="11">
                    <v-icon>mdi-clipboard-outline</v-icon>
                    Total de anexos para download:
                    <span  class="attach-number">
                      {{dataSupplier.files.length}}
                    </span>
                  </v-col>
                  <v-col md="8" cols="12" sm="6">
                    <v-select class="select-items" :items="dataSupplier.files"
                      v-model="filesPreview" label="Selecionar anexo" dense outlined>
                      <template v-slot:selection="{ item }">
                        <v-chip>
                          <span>{{`${item.link.split('/')[6].substr(0, 20)}...`}}</span>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-chip>
                          <span>{{`${item.link.split('/')[6].substr(0, 20)}...`}}</span>
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col md="3">
                    <img :src="urlImage" alt="" width="500" height="400">
                    <div class="action_file" @click="downloadAttachment"> Download </div>
                  </v-col>
                </v-row>
              </v-row>
            </v-form>
          </v-container>
          <v-row class="success-text" v-if="requestAprove">
            <v-col cols="12">
              <img class="imgSuccess" src="@/assets/success.png" />
              <h5 class="succesTitle">Solicitação aprovada com Sucesso</h5>
              <span class="successDescription">
                A solicitação foi aprovada com sucesso.
                O valor será transferido ao fornecedor.
              </span>
            </v-col>
          </v-row>
          <v-row class="success-text" v-if="requestReprove">
            <v-col cols="12">
              <img class="imgSuccess" src="@/assets/reprove.png" />
              <h5 class="succesReprove">Solicitação reprovada com Sucesso.</h5>
              <span
                class="reproveDescription"
              >A solicitação for reprovada com sucesso, o Fornecedor será notificado.</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="btn-actions" v-if="!requestAprove && !requestReprove && dataSupplier.status == 0 ">
          <v-btn @click="reprove"  class="btnBack" :loading="btnLoadingReprove">Reprovar</v-btn>
          <v-btn @click="aprove" class="btnRequest" :loading="btnLoadingAprove">Aprovar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mask } from 'vue-the-mask';
import { VMoney } from 'v-money';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import service from '../../../service';

export default {
  directives: {
    mask,
    money: VMoney,
  },
  props: ['dataSupplier'],
  data() {
    return {
      filesRequest: ['NotaFiscal__01/02', 'NotaFiscal_05/02'],
      btnLoadingAprove: false,
      btnLoadingReprove: false,
      showModal: true,
      dialog: false,
      singleStatus: true,
      fileSelected: null,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
      },
      dataRequest: {},
      requestError: 'requestError',
      requestAprove: false,
      requestReprove: false,
      document: '',
      service,
      filesPreview: '',
      urlImage: '',
    };
  },
  watch: {
    dialog(value) {
      if (value === false) {
        this.closeModal();
      }
    },
    document(value) {
      if (value && value.length === 18) {
        this.requestFromApi(value);
      }
    },
  },
  computed: {
    ...mapGetters({
      getError: 'Request/getError',
      getAvailableValuesForRequest: 'Request/getAvailableValuesForRequest',
      getAproveSchedule: 'Request/getAproveSchedule',
      getReproveSchedule: 'Request/getReproveSchedule',
      export: 'Request/getExport',
      fileName: 'Request/getFileName',
    }),
    validationCNPJ() {
      return [
        value => !!value || 'CNPJ obrigatório',
        value => (value && value.length > 17) || 'Deve conter 14 números',
      ];
    },
    validationRequest() {
      return [value => !!value || 'Pedido obrigatório'];
    },
    validationValue() {
      return [value => !!value || 'Valor obrigatório'];
    },
    validationSocial() {
      return [value => !!value || 'Razão social obrigatório'];
    },
    getRequest() {
      return this.getSellerRequest;
    },
    responseError() {
      return this.getError;
    },
    successAprove() {
      return this.getAproveSchedule;
    },
    successReprove() {
      return this.getReproveSchedule;
    },
    image() {
      return this.export;
    },
  },
  methods: {
    ...mapActions({
      aproveSchedule: 'Request/aproveSchedule',
      reproveSchedule: 'Request/reproveSchedule',
      downloadFileAttached: 'Request/downloadFileAttached',
    }),
    ...mapMutations({ CLEAR_NEW_TRANSFER: 'Request/CLEAR_NEW_TRANSFER', CLEAR_ERROR: 'Request/CLEAR_ERROR' }),
    async requestFromApi(_payload) {
      const cnpj = this.service.cleanDocument(_payload);
      await this.setSellerSolicitation(cnpj);
      this.dataRequest = this.getRequest;
    },
    async downloadAttachment() {
      await this.downloadFileAttached(this.filesPreview.link);
    },
    closeModal() {
      this.dialog = false;
      this.requestReprove = false;
      this.requestAprove = false;
      this.optionsValue = '';
      this.showModal = false;
      this.btnLoadingAprove = false;
      this.btnLoadingReprove = false;
      this.filesPreview = '';
      this.CLEAR_NEW_TRANSFER();
      this.CLEAR_ERROR();
    },
    openModal() {
      this.showModal = true;
    },
    async aprove() {
      this.btnLoadingAprove = true;
      await this.aproveSchedule(this.dataSupplier._id);
      if (this.successAprove) {
        this.requestAprove = true;
        this.$emit('emitFunction', { perPage: 10 });
      }
      this.btnLoadingAprove = false;
    },
    async reprove() {
      this.btnLoadingReprove = true;
      await this.reproveSchedule(this.dataSupplier._id);
      if (this.successReprove) {
        this.$emit('emitFunction', { perPage: 10 });
        this.requestReprove = true;
      }
      this.btnLoadingReprove = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/seller/solicitation.scss';
</style>
