<template>
  <v-row justify="center" class="filter-panel">
    <v-expansion-panels v-model="panel" popout>
      <v-expansion-panel
        v-for="(item,i) in 1"
        :key="i"
      >
        <v-expansion-panel-header @click="showTable">Filtrar por</v-expansion-panel-header>
        <v-expansion-panel-content>
            <div>
                <v-col>
                <div class="date-filter filters-select">
                    <DateRanger :emitFunction="'pushRangeDate'" />
                </div>
                <div class="filters-select">
                  <v-select
                    class="select-filter"
                    item-color="#D70472"
                    v-model="selectStatus"
                    :items="status"
                    label="Status"
                    multiple
                    single-line
                    outlined
                  >
                  <template class="items-chips">
                    <v-chip v-if="index === 0">
                      <span class="list-items">oi</span>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="grey--text caption"
                    >(+{{ selectStatus.length - 1 }})</span>
                  </template>
                </v-select>
                </div>

                <div class="iconSearchFilters">
                    <input
                    class="inputSearchFilters"
                    v-mask="'##.###.###/####-##'"
                    v-model="document"
                    label="Search"
                    placeholder="CNPJ"
                    />
                </div>

                <div class="iconSearchFilters">
                    <input
                    type="number"
                    class="inputSearchFilters"
                    v-model="externalId"
                    label="Search"
                    placeholder="Pedido"
                    />
                </div>

                <v-btn
                    @click="firstFilter"
                    :loading="btnLoading"
                    class="ma-1 buttonFilter"
                    small
                    outlined
                >Filtrar</v-btn>
                </v-col>
            </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import { mask } from 'vue-the-mask';
import DateRanger from '../Date/DateRanger.vue';

export default {
  directives: {
    mask,
  },
  components: {
    DateRanger,
  },
  name: 'Filters',
  props: ['status'],
  data() {
    return {
      document: '',
      startDate: '',
      endDate: '',
      dialog: false,
      page: 1,
      btnLoading: false,
      sendStatus: null,
      tableAction: true,
      externalId: '',
      selectStatus: [],
      panel: [],
      items: 1,
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
  },
  methods: {
    firstFilter() {
      this.page = 1;
      this.panel = [];
      this.filter();
      this.showTable();
    },
    showTable() {
      if (this.tableAction === true) {
        this.tableAction = false;
        this.$bus.$emit('tableVisible', false);
      } else {
        this.tableAction = true;
        this.$bus.$emit('tableVisible', true);
      }
    },
    filter() {
      const { page } = this;
      const args = { };
      if (page) args.page = page;
      if (this.document) args.document = this.document.replace(/\D/gim, '');
      if (this.externalId) args.externalId = this.externalId;
      if (this.selectStatus.length >= 1) args.status = this.selectStatus;
      if (this.startDate) args.startDate = this.startDate;
      if (this.endDate) args.endDate = this.endDate;
      args.perPage = 10;

      this.btnLoading = true;
      this.$emit('emitFunction', args);
    },
  },
  created() {
    this.$bus.$on('pushRangeDate', (payload) => {
      const data = payload.split(' ~ ');
      this.startDate = this.$moment(data[0], 'DD/MM/YYYY').format();
      this.endDate = this.$moment(data[1], 'DD/MM/YYYY').format();
    });
    this.$bus.$on('paginationFilter', (payload) => {
      this.page = payload;
      this.filter();
    });
    this.$bus.$on('loadingButtonFilters', (payload) => {
      this.btnLoading = payload;
    });
  },
};
</script>

<style lang="scss" scoped>
*:focus {
  outline: 0;
}
.iconSearchFilters {
    border-bottom: 0.1px solid #888888;
    color: #7d7d7d !important;
    font-size: 15px;
    font-weight: bold;
    height: 70px;
    width: 126%;
    position: static;
    margin-left: -13%;
}
.filters-select {
    color: #7d7d7d !important;
    font-size: 15px;
    font-weight: bold;
    height: 70px;
    width: 126%;
    position: static;
    margin-left: -13%;
}

.date-filter {
  margin-bottom: none;
}

.filters-select::v-deep .v-select__slot {
  height: 70px;
}
.filters-select::v-deep .v-label.theme--light{
  margin-left: 40%;
  margin-top: 2%;
}

.filters-select::v-deep .v-select__selections {
  margin-left: 35%;
}

.filters-select::v-deep .v-icon.notranslate.mdi.mdi-menu-down.theme--light {
  margin-right: 300%;
  margin-top: 100%;
  margin-right: 200%;
}

.DateRanger {
  cursor: pointer;
  color: #7d7d7d !important;
  font-size: 15px;
  font-weight: bold;
}
.selectStatus {
  cursor: pointer;
  width: 100%;
  border-radius: 6px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: -0.022em;
  color: #7d7d7d !important;
  z-index: 99;
  padding-left: 42% !important;
  padding-top: 7%;
}
.iconSelect {
  position: absolute;
  margin-left: -30px;
  z-index: -1;
}
.filter-text {
  font-size: 80%;
  font-weight: bold;
  color: #8c8c8c;
}
.buttonFilter {
background: #d70472;
border-radius: 24px;
color: white;
padding: 4% 6% 4% 6%;
font-size: 0.9rem;
width: 100%;
margin-top: 30% !important;
}
.v-btn {
    height: 40px !important;
}
.inputSearchFilters{
    padding: 28px 7px 7px 25px !important;
  color: #7d7d7d !important;
  font-size: 15px;
  font-weight: bold;
    padding-left: 42% !important;
}
.selectStatus {
    color: #7d7d7d !important;
    font-size: 15px;
    font-weight: bold;
}
.inputSearchFilters:focus {
  outline: 0;
}
.dateInput {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  margin-left: 10px;
  margin-bottom: 20px;
  width: 86%;
  border-radius: 6px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.022em;
  color: #7d7d7d ;
  padding: 2px 7px 7px 7px;
  z-index: 99;
}
.dateInput:focus {
  outline: 0;
}
.selectStatus option {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.022em;
  color: #7d7d7d;
}

.v-expansion-panel-header  {
    color:#7d7d7d ;
    font-weight: bold;
}
.v-expansion-panel-header__icon {
        padding-left: 60%;
}
.filter-panel::v-deep .v-expansion-panels {
  z-index: 0;
}
.select-filter::v-deep .v-input__slot {
  height: 70px !important;
}
.select-filter::v-deep .v-label.theme--light {
  margin-top: 8%;
}
.select-filter::v-deep .v-icon.notranslate.mdi.mdi-menu-down.theme--light {
  margin-top: 200%;
}
.select-filter::v-deep .v-select__selections {
  margin-top: 10%;
}
.date-filter::v-deep .inputSearchFilters.DateRanger {
  font-size: 15px;
  color: #7d7d7d !important;
}

</style>
