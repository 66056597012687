<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400">
      <template v-slot:activator="{ on }">
        <div v-on="on" @click="dialog = true">
          <input
            type="text"
            v-model="labelDate"
            disabled
            id="date-filter"
            class="inputSearchFilters DateRanger"
          />
        </div>
      </template>
      <v-card>
        <v-date-picker locale="pt-BR" full-width no-title v-model="dates" range></v-date-picker>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col>
            <v-btn class="cancelDate" text @click="cancel">Cancelar</v-btn>
          </v-col>
          <v-col>
            <v-btn class="selectDate" text @click="sendDate">Selecionar</v-btn>
          </v-col>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'DatePicker',
  props: ['emitFunction'],
  data() {
    return {
      dialog: false,
      dates: [this.$moment().format('YYYY-MM-DD')],
      labelDate: 'Data da solicitação',
    };
  },
  computed: {
    dateRangeText() {
      let initDatePicker = this.dates[0] ? this.$moment(this.dates[0]).format('DD/MM/YYYY') : '';
      let endDatePicker = this.dates[1] ? this.$moment(this.dates[1]).format('DD/MM/YYYY') : '';
      if (this.$moment(this.dates[0]).isAfter(this.dates[1])) {
        initDatePicker = this.$moment(this.dates[1]).format('DD/MM/YYYY');
        endDatePicker = this.$moment(this.dates[0]).format('DD/MM/YYYY');
      }
      if (endDatePicker === '' && initDatePicker !== '') {
        endDatePicker = initDatePicker;
      }
      const data = `${initDatePicker} ~ ${endDatePicker}`;
      document.getElementById('date-filter').style.fontSize = '12px';
      return data;
    },
  },
  methods: {
    sendDate() {
      this.dialog = false;
      this.labelDate = this.dateRangeText;
      this.$bus.$emit(`${this.emitFunction}`, this.dateRangeText);
    },
    cancel() {
      this.dialog = false;
      document.getElementById('date-filter').style.fontSize = '16px';
      this.labelDate = 'Data da solicitação';
      this.$bus.$emit(`${this.emitFunction}`, null);
    },
  },
};
</script>

<style lang="scss" scoped>
.DateRanger {
  cursor: pointer;
}
.inputSearchFilters {
  border-radius: 6px;
  line-height: 15px;
  letter-spacing: -0.022em;
  z-index: 99;
  color: #7d7d7d ;
  width: 150px;
  font-size: 16px;
  text-align: left;
}

.inputSearchFilters:focus {
  outline: 0;
}
.iconSelect {
  position: absolute;
  margin-left: -5px;
  z-index: -1;
}
.selectDate {
  background: #00c1c1;
  border-radius: 24px;
  color: white;
}
.cancelDate {
  background: #d70472;
  border-radius: 24px;
  color: white;
}

@media only screen and (max-device-width: 767px) {
.inputSearchFilters{
        width: 100%;
        margin-left: 15%;
        border-radius: 6px;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: -0.022em;
        color: #756e6e;
        z-index: 99;
        padding-top: 12%;
    }
}
</style>
